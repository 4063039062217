import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SecondaryHeader from "../../components/SecondaryHeader"
import CustomLink from "../../components/CustomLink"
import SupportCenterTopicWrapper from "../../components/SupportCenterLayout"

const ThePatientsDashboardPage = () => {
  return (
    <Layout>
      <Seo title={`Easy to use cloud platform`} />
      <SecondaryHeader title={<>Support Center</>} />

      <div id="support-center-page">
        <section className="support-center">
          <div style={{ paddingBottom: "50px" }}></div>

          <SupportCenterTopicWrapper>
            <div className="support-center__topic-right">
              <h3 className="mb-4">Understanding the Orders Dashboard</h3>

              <p>
                Take control of the entire aligner treatment plan development
                cycle from the Orders Dashboard - managing your orders has never
                been easier!
              </p>

              <div className="mb-4" />

              <h5 id="Creating an Order for a Patient">
                Title: <strong>Creating an Order for a Patient</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Sign in to AlignerBase.
              </p>

              <div className="mb-4" />

              <p>
                Having trouble signing in? View{" "}
                <CustomLink
                  title="Signing in to AlignerBase Dashboard."
                  link="/support-center/signing-into-your-dashboard"
                />
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the main dashboard, click on{" "}
                <strong>Patients</strong> from the left side panel.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/5.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - Locate the patient you want to start a
                new order for. This can be done either by searching for the
                patient or the doctor the patient is assigned to.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - After locating, you can click on
                "Create new order" button present in the patients row under the
                Actions column.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/6.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 5</strong> - There are 3 stages to creating an
                order.
              </p>

              <div className="mb-4" />

              <ul class="list-disc pl-4">
                <li>
                  First Stage - Scans and Imaging:
                  <div className="mb-4" />
                  <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/6a.jpg" />
                  <ul class="list-circle pl-4">
                    <li>
                      You need to upload Scans, Photos and X-Rays.
                      <ul class="list-square pl-4">
                        <li>
                          <strong>Scans</strong> are mandatory and need to be an
                          intra oral scan (which may be Maxillary, Mandibular or
                          both) which can be either obtained through an
                          intra-oral scanner or from a lab/desktop scan of an
                          impression.{" "}
                          <em>Files Supported include STL and DCM.</em>
                        </li>
                        <li>
                          <strong>Photos</strong> are mandatory and include a
                          full-face (frontal) smiling image and 3 intra-oral
                          images including a right lateral, left lateral and
                          frontal view. You may also upload upper and lower
                          occlusal views.
                        </li>
                        <li>
                          <strong>X-Rays</strong> are but highly recommended
                          especially where cases have higher complexity. You can
                          upload a full panoramic X-ray here.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  Second Stage - Prescription:
                  <div className="mb-4" />
                  <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/6b.jpg" />
                  <ul class="list-circle pl-4">
                    <li>
                      Here you need to add instructions regarding the case,
                      technicalities corresponding to the case and the complete
                      patient Rx.
                      <ul class="list-square pl-4">
                        <li>
                          <strong>Duration</strong> can be set either to a
                          recommended number of steps or a limited amount that
                          you prescribe. If you leave this on Recommend, we'll
                          show you how many steps we need to try to reach your
                          prescribed goals on the treatment setup. If you choose
                          Limit, you can restrict the duration of the treatment.
                        </li>
                        <li>
                          <strong>Existing Condition</strong> requires you to
                          input the chief complaint and problem that needs to be
                          addressed. This should be a clear summary of the
                          conditions and issues you and the patient want to
                          resolve.
                        </li>
                        <li>
                          <strong>Instruction</strong> is where you have all the
                          technical instruction set available to modify. Default
                          options are already selected which you can edit to
                          your specific case. The default option for most
                          conditions is to improve them as much as reasonably
                          possible within the selected case type. Be sure to
                          tell us how you want to improve the conditions in the
                          "Additional Information" area.
                          <ul class="list-disc pl-4">
                            <li>
                              Further, by default, both arches / jaws are added
                              to the treatment. If you want a single jaw to be
                              treated, you can select it under "Treat Arches".
                              Singular jaw treatments have lower prices.
                            </li>
                          </ul>
                        </li>
                        <li>
                          You can also specify which teeth to avoid engagers on,
                          which teeth will be extracted before treatment, which
                          spaces to leave open and which teeth should not be
                          moved.
                          <ul class="list-disc pl-4">
                            <li>
                              The Universal Notation is used to number the
                              teeth.
                            </li>
                          </ul>
                        </li>

                        <li>
                          As a final step to the treatment, there is an option
                          to include a retainer for the patient to retain the
                          whole treatment and this is complementary. If you
                          uncheck it, all the retainers in the treatment will be
                          made except for the last retainer that patient can
                          wear after completion.
                        </li>

                        <li>
                          At the end, you can also add additional instructions
                          that haven't been conveyed.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Third Stage - Submit
                      <ul class="list-square pl-4">
                        <li>
                          Here you can confirm all the data and choose to submit
                          the order.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>

              <div className="mb-4" />

              <h5 id="Viewing an Existing Order">
                Title: <strong>Viewing an Existing Order</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Sign in to AlignerBase.
              </p>

              <div className="mb-4" />

              <p>
                Having trouble signing in? View{" "}
                <CustomLink
                  title="Signing in to AlignerBase Dashboard."
                  link="/support-center/signing-into-your-dashboard"
                />
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the main dashboard, click on{" "}
                <strong>Patients</strong> from the left side panel.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - Locate the patient you want to view
                the order for. This can be done either by searching for the
                patient or the doctor the patient is assigned to.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - After locating, you can click on
                "View" button present in the patients row under the Actions
                column.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/7.jpg" />

              <div className="mb-4" />

              <p>
                From here you can view the complete order you submitted along
                with the ability to download the complete order as PDF.
                Additionally, you can also click on "Open Chat" button while
                viewing the order at the top right to convey any additional
                information, remarks or queries regarding the order.
              </p>

              <div className="mb-4" />

              <h5 id="Cancelling an Order">
                Title: <strong>Cancelling an Order</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Sign in to AlignerBase.
              </p>

              <div className="mb-4" />

              <p>
                Having trouble signing in? View{" "}
                <CustomLink
                  title="Signing in to AlignerBase Dashboard."
                  link="/support-center/signing-into-your-dashboard"
                />
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the main dashboard, click on{" "}
                <strong>Orders</strong> from the left side panel.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - Locate the Order you want to cancel
                the order for. This can be done either by searching for the
                Order by Patient Name, Doctor Name or Case ID, or by using the
                'Order Status' filter and selecting the applicable order status
                from the drop-down menu.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - After locating the order, you can
                click on "Cancel" button present in the orders row under the
                Actions column.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/8.jpg" />

              <div className="mb-4" />

              <p>
                It should be noted that orders can only be cancelled if they are
                in the Draft, Under Review or Approval Required stages. Orders
                cannot be cancelled if they are in the Design in Progress stage.
              </p>

              <div className="mb-4" />

              <p>
                If the order is on the Under Review stage, this cancellation
                will not be charged. However, for orders Under Progress, a fee
                of $35USD will be charged.
                <CustomLink
                  title="Please click here for further details on Plans and Pricing."
                  link="/plan-and-pricing"
                />
              </p>

              <div className="mb-4" />

              <h5 id="Editing Details of a Patient">
                Title: <strong>Editing Details of a Patient</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Sign in to AlignerBase.
              </p>

              <div className="mb-4" />

              <p>
                Having trouble signing in? View{" "}
                <CustomLink
                  title="Signing in to AlignerBase Dashboard."
                  link="/support-center/signing-into-your-dashboard"
                />
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the main dashboard, click on{" "}
                <strong>Patients</strong> from the left side panel.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - Locate the patient you want to edit
                details for. This can be done either by searching for the
                patient or the doctor the patient is assigned to.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - After locating, you can click on
                "Edit" button present in the patients row under the Actions
                column.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/9.jpg" />

              <div className="mb-4" />

              <h5 id="Order Progression after submission to AlignerBase Team">
                Title:{" "}
                <strong>
                  Order Progression after submission to AlignerBase Team
                </strong>
              </h5>

              <div className="mb-4" />

              <p>
                After you have submitted a new order to AlignerBase, our team
                will start to develop the treatment plan.
              </p>

              <div className="mb-4" />

              <p>
                On the Main Dashboard, under the Cases by Treatment Stage
                section, the count of Under Review will increase after you have
                completely submitted a new case.
              </p>

              <div className="mb-4" />

              <p>
                After our team reviews your case, the case will move from Under
                Review to Design in Progress. This will also be reflected as a
                decrease in your Under Review number and increase in the Design
                in Progress number. Similarly, you will be alerted for this via
                email as well as receive an alert in the alerts section on the
                dashboard itself.
              </p>

              <div className="mb-4" />

              <p>
                Further, once our design process is completed, the case will
                move to Design in QC stage where the team will quality check the
                design. Similar to the previous stages, you will also be alerted
                via email and in the alerts section along with a count change.
              </p>

              <div className="mb-4" />

              <p>
                After quality check is complete, the case will be moved to
                approval required stage. For this, again, you will be alerted
                via email and in the alerts section. This is where AlignerBase
                team will require your input and you need to review the
                treatment plan.
              </p>

              <div className="mb-4" />

              <h5 id="Approving a Treatment Plan">
                Title: <strong>Approving a Treatment Plan</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - After you have been notified of a case
                that requires your approval, you need to navigate to the Orders
                section by clicking on Orders on the left navigation pane from
                the main dashboard.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/10.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the list, you can see the case(s)
                where your approval is required. Click on View in the same row
                to review the treatment plan.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - Once you click view, you will see the
                complete Order just as before but this time, you will see a new
                section after Scans and Imaging, named Proposed Treatment plan.
                Click on that.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/10a.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - Here you can see the PDF of the
                treatment plan as well as a video. Download or view both.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/10b.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 5</strong> - After you have reviewed the plan, you
                can click on <em>Approve Order</em> or <em>Request Revision</em>{" "}
                depending upon your feedback. You can also cancel the order at
                this stage as well, however, you will be charged 35$USD.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/10c.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 6</strong> -If you are on the Pay as you Go plan,
                you will be asked to pay for the order before AlignerBase
                releases the final Designed Model. If you are on a subscription
                plan, clicking on Approve will add this order to your monthly
                bill / count.
              </p>

              <div className="mb-4" />

              <p>
                Final files are made available in STL and editable 3sz file
                formats using 3 Shape software as default.{" "}
                <CustomLink title="Contact us" link="/contact-us" /> here to if
                you wish to receive editable files for software other than 3
                Shape.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/10d.jpg" />

              <div className="mb-4" />

              <h5 id="Refinements after Approval">
                Title: <strong>Refinements after Approval</strong>
              </h5>

              <div className="mb-4" />

              <p>
                Before approval of an order, you can request any number of
                revisions however, once an order is approved, you can only
                request a refinement (mid-course correction). This will be free
                of charge for the first time, but will incur an additional
                $35USD the second time onwards.
              </p>

              <div className="mb-4" />

              <p>
                To request a refinement, you can view your approved order and
                click on Request Refinement button on the top right.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/11.jpg" />

              <div className="mb-4" />

              <p>
                This will move the order back to draft stage and give you the
                option to go over the data once again just like as if it was a
                new order but retaining the older information you inputted. Make
                any changes here that you will like done differently this time
                round and then click on Submit.
              </p>

              <div className="mb-4" />

              <p>
                After AlignerBase team finishes designing, you will be notified
                of approval once again. This time, you will see the revised plan
                along with the old plan in the approval section. You can approve
                this new plan or request for further revision (which is free of
                cost before approval).
              </p>

              <div className="mb-4" />

              <h5 id="In case of Order Rejection from AlignerBase">
                Title:{" "}
                <strong>In case of Order Rejection from AlignerBase</strong>
              </h5>

              <div className="mb-4" />

              <p>
                Due to varying reasons, an order submission can be rejected with
                remarks as to why it has been rejected. To view and resubmit
                order after corrections, do the following.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/12.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Navigate to Orders section through the
                left navigation on the main dashboard.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - Locate the order where it says "Review
                and Resubmit Order" and click on View. Alternatively, you can
                click on the alert regarding the same order in your
                notifications section or alerts section.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - You will find a remarks section where
                the AlignerBase team would have conveyed what needs to be done
                before progressing.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/12b.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - According to the remarks, edit your
                order and submit. This will move your order back to under review
                and the AlignerBase team will begin to progress with the order.
              </p>
            </div>
          </SupportCenterTopicWrapper>
        </section>
      </div>
    </Layout>
  )
}

export default ThePatientsDashboardPage
